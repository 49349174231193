import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Colors from '../constants/Colors'
import TestimonialCard from '../components/general/TestimonialCard'
import SectionTitle from '../components/general/SectionTitle'
import { useTranslation } from 'react-i18next';

const TestimonialSection = () => {
    const { t } = useTranslation();

    const testimonials = t('testimonials.list');

    return (
        <div className='py-5' style={{ backgroundColor: Colors.blue }}>
            <div className='container py-5'>
                <SectionTitle text={t("testimonials.title")} color={Colors.white} />
                <Carousel
                    indicators={false}
                    nextIcon={<span className="fa-solid fa-caret-right fs-50" />}
                    prevIcon={<span className="fa-solid fa-caret-left fs-50" />}
                >
                    <Carousel.Item>
                        <div className='container mt-5'>
                            <div className="row row-cols-1 row-cols-lg-2 d-flex justify-content-around g-4">
                                <div className='col col-md-8 col-xl-4'>
                                    <TestimonialCard data={testimonials[0]} />
                                </div>
                                <div className='col col-md-8 col-xl-4'>
                                    <TestimonialCard data={testimonials[1]} />
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className='container mt-5'>
                            <div className="row row-cols-1 row-cols-lg-2 d-flex justify-content-around g-4">
                                <div className='col col-md-8 col-xl-4'>
                                    <TestimonialCard data={testimonials[2]} />
                                </div>
                                <div className='col col-md-8 col-xl-4'>
                                    <TestimonialCard data={testimonials[3]} />
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}

export default TestimonialSection