import React from 'react'

const TestimonialCard = ({ data }) => {
    const { name, description } = data;
    return (
        <div className='testimonial-card d-flex flex-column justify-content-between pb-4 pb-md-0'>
            <p className='fw-medium fs-22 text-white fst-italic'>{`"${description}"`}</p>
            <p className='fw-bold fs-14 text-white m-0' style={{ opacity: '0.7' }}>{name}</p>
        </div>
    )
}

export default TestimonialCard