import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../redux/slices/postsSlice';

export const usePosts = () => {
    const dispatch = useDispatch();
    const { posts, status } = useSelector(state => state.posts);

    const fetchPostsAsync = () => { dispatch(fetchPosts()) }

    useEffect(() => {
        fetchPostsAsync();
    }, []);

    return [posts, status];
}

export default usePosts;