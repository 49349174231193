import React from 'react'

const SectionTitle = ({ text, color }) => {
    return (
        <p className='fw-bold fs-48 text-center ls-15 text-uppercase' style={{ color: color }}>
            {text}
        </p>
    )
}

export default SectionTitle