import { Suspense } from 'react';
import MainLayout from './layout/MainLayout';
import HomePage from './pages/HomePage';

const App = () => {
  return (
    <MainLayout>
      <HomePage />
    </MainLayout>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  );
}
