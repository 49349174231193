import React from 'react'
import RRSSLinksData from '../../data/RRSSLinksData'

const RRSSLinks = () => {
    return (
        <div className='d-flex justify-content-center justify-content-lg-end flex-wrap flex-lg-nowrap'>
            {
                RRSSLinksData.map((item, index) => {
                    return (
                        <div key={index} className='rrss-border'>
                            <a className='d-flex align-items-center justify-content-center text-decoration-none' href={item.link} target="_blank" rel="noreferrer">
                                {
                                    item.icon === 'gettr' ?
                                        <img src='/images/gettr.png' alt='gettr' style={{ width: 'auto', height: 20 }} />
                                        :
                                        <i className={`${item.icon} text-white`} style={{ fontSize: 20 }}></i>
                                }
                            </a>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RRSSLinks