import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Brand from './Brand'
import Colors from '../../constants/Colors'
// import SearchBar from './SearchBar'
import ChangeLanguage from './ChangeLanguage'
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();

    const AnchorTags = t('header');

    return (
        <Navbar className='position-fixed w-100 shadow-lg' style={{ backgroundColor: Colors.blue, zIndex: 100 }} expand="xl" variant='dark'>
            <div className='container'>
                <Navbar.Brand href="/"><Brand /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto mt-3 mt-xl-0">
                        {AnchorTags.map((link, index) => {
                            return (
                                link.ext ?
                                    <a target="_blank" rel='noreferrer' key={index} href={`${link.url}`} className='me-lg-4 nav-link' ><Title text={link.title} /></a> :
                                    <Nav.Link key={index} href={`/#${link.url}`} className='me-lg-4' > <Title text={link.title} /></Nav.Link>
                            )
                        })}
                        <div className='d-flex flex-grow-0 justify-content-end flex-row'>
                            {/* <SearchBar /> */}
                            <ChangeLanguage />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    )
}

const Title = ({ text }) => {
    return <span className='' style={{ color: Colors.white }}>{text}</span>
}


export default Header