export const Colors = {
    blue: '#3888A3',
    green: '#9FC248',
    white: '#FFFEFE',
    orange: '#F1AC40',
    grey: '#EBEAED',
    red: '#E9464D',
    lightGray: "#727272",
    hardBlue: '#265880',
    black: '#000000'
}

export default Colors;