import Carousel from 'react-bootstrap/Carousel'
import { useTranslation } from 'react-i18next';

const Hero = () => {
    const { t } = useTranslation();

    const HeroImages = t("hero");
    return (
        <div className='pt-5' style={{ backgroundColor: '#3888A3' }}>
            <div className='container pt-3 pt-0'>
                <Carousel
                    nextIcon={<span aria-hidden="true" className="fa-solid fa-caret-right fs-50" />}
                    prevIcon={<span aria-hidden="true" className="fa-solid fa-caret-left fs-50" />}
                >
                    {
                        HeroImages.map((imageUrl, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <img className='d-block w-100' src={`${process.env.PUBLIC_URL}/images/hero/${imageUrl}`} alt={imageUrl} />
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default Hero