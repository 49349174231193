import Colors from '../../constants/Colors'
import RRSSLinks from './RRSSLinks'
import Images from '../../constants/Images'

const Footer = () => {
    return (
        <div className='d-flex justify-content-center py-2' style={{ backgroundColor: Colors.blue }}>
            <div className='d-flex flex-column flex-xl-row justify-content-between align-items-center py-4 w-100 px-3 px-xl-5'>
                <div className='col mb-2 mb-xl-0'>
                    <img className='tag-logo' src={Images.tagLogo} alt="listica-logo"></img>
                </div>
                <div className='col'>
                    <div className='d-flex flex-column my-5 pb-5 my-xl-0' style={{ fontSize: '18px', height: '18px', lineHeight: '18px' }}>
                        <span className='text-white fw-bold text-center pb-2'>GUANACASTE, COSTA RICA</span>
                        <a className='text-white fw-bold text-center text-decoration-none pb-2' href='tel:(+506) 40012332' target="_blank" rel="noreferrer">+506 4001 2332</a>
                        <a className='text-white fw-bold text-center text-decoration-none' href='mailto:info@listica.com' target="_blank" rel="noreferrer">info@listica.com</a>
                    </div>
                </div>
                <div className='col'>
                    <div className='d-flex flex-column-reverse flex-md-row align-items-center justify-content-md-end '>
                        <img style={{ maxHeight: "120px", width: "auto", backgroundColor: "white" }} className="p-2 mt-2 mt-md-0 me-md-2" src="/images/pyme-logo.png" alt="" />
                        <RRSSLinks />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer