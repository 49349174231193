import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = { posts: [], status: "" };

export const fetchPosts = createAsyncThunk("posts/fetchPosts", async () => {
  try {
    const { data } = await axios.get(
      "https://listicablog.com/wp-json/wp/v2/posts/"
    );
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const postsSlice = createSlice({
  name: "posts",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPosts.pending, (state) => {
      state.status = "loading"
    })
    builder.addCase(fetchPosts.fulfilled, (state, action) => {
      state.status = "success";
      state.posts = action.payload;
    })
    builder.addCase(fetchPosts.rejected, (state) => {
      state.status = "failed"
    })
  }
});

export default postsSlice.reducer;