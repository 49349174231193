import React from 'react'

const UsCard = ({ colaborator }) => {
    return (
        <div className='d-flex justify-content-center'>
            <div className='d-flex flex-column align-items-center quote-card text-center'>
                <i className="fa-solid fa-quote-left fs-32 text-white mb-5"></i>
                <p className='fs-22 fw-bold text-white mb-5 quote-text'>{colaborator.quote}</p>
                <img className='rounded-circle mb-4 mt-1' style={{ width: '160px', height: '160px' }} src={`${process.env.PUBLIC_URL}/images/collaborators/${colaborator.imgUrl}`} alt="" />
                <p className='fs-18 fw-medium text-white mb-2'>{colaborator.name}</p>
                <p className='fs-14 fw-bold text-white'>{colaborator.position.toUpperCase()}</p>
            </div>
        </div>
    )
}

export default UsCard