import React from 'react'
import Colors from '../../constants/Colors'

const PortfolioCard = ({ data }) => {
    const { title, subtitle, firstText, secondText, imgURL, type, titleColor } = data;
    return (
        <div className='d-flex justify-content-center'>
            <div className='portfolio-card bg-white m-3 m-sm-5 d-flex'>
                <div className='row flex-column flex-md-row d-flex align-items-center g-0'>
                    {
                        type === 'web' &&
                        <>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center px-4 px-sm-5 pe-md-0'>
                                    <span className='d-none d-xl-block fw-bold fs-14 text-uppercase' style={{ color: Colors.lightGray }}>{subtitle}</span>
                                    <p className='fw-bold fs-30 w-75 text-uppercase' style={{ color: titleColor }}>{title}</p>
                                    <p className='fs-16 fw-medium' style={{ color: Colors.lightGray }}>{firstText}</p>
                                    <p className='d-none d-xl-block fs-16subtitle' style={{ color: Colors.lightGray }}>{secondText}</p>
                                </div>
                            </div>
                            <div className='col-12 col-md-5 col-4'>
                                <div className=' d-flex justify-content-end'>
                                    <img className='portfolio-img' src={`${process.env.PUBLIC_URL}/images/portfolio/${imgURL}`} alt="" />
                                </div>
                            </div>
                        </>
                    }
                    {
                        type === 'tablet' &&
                        <>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center px-4 px-sm-5 pe-md-0'>
                                    <span className='d-none d-xl-block fw-bold fs-14 text-uppercase' style={{ color: Colors.lightGray }}>{subtitle}</span>
                                    <p className='fw-bold fs-30 w-75 text-uppercase' style={{ color: titleColor }}>{title}</p>
                                    <p className='fs-16 fw-medium' style={{ color: Colors.lightGray }}>{firstText}</p>
                                    <p className='d-none d-xl-block fs-16subtitle' style={{ color: Colors.lightGray }}>{secondText}</p>
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex justify-content-center'>
                                    <img className='portfolio-img' src={`${process.env.PUBLIC_URL}/images/portfolio/${imgURL}`} alt="" />
                                </div>
                            </div>
                        </>
                    }
                    {
                        type === 'mobile' &&
                        <>
                            <div className='col col-sm-5 col-md-4'>
                                <div className=' d-flex justify-content-center'>
                                    <img className='portfolio-img' src={`${process.env.PUBLIC_URL}/images/portfolio/${imgURL}`} alt="" />
                                </div>
                            </div>
                            <div className='col'>
                                <div className='d-flex flex-column justify-content-center px-5 ps-md-0'>
                                    <span className='d-none d-xl-block fw-bold fs-14 text-uppercase' style={{ color: Colors.lightGray }}>{subtitle}</span>
                                    <p className='fw-bold fs-30 w-75 text-uppercase' style={{ color: titleColor }}>{title}</p>
                                    <p className='fs-16 fw-medium' style={{ color: Colors.lightGray }}>{firstText}</p>
                                    <p className='d-none d-xl-block fs-16subtitle' style={{ color: Colors.lightGray }}>{secondText}</p>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    )
}

export default PortfolioCard