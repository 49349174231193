import React, { useEffect } from 'react'
import HeroSection from '../sections/HeroSection'
import ServicesSection from '../sections/ServicesSection'
import PortfolioSection from '../sections/PortfolioSection'
import TestimonialSection from '../sections/TestimonialSection'
import UsSection from '../sections/UsSection'
import BlogSection from '../sections/BlogSection'
// import ContactSection from '../sections/ContactSection'
import Alliances from '../sections/Alliances'
import BackToTop from '../components/general/BackToTop'
import { useTranslation } from 'react-i18next';

const HomePage = () => {
    const { t } = useTranslation();
    const AnchorTags = t('header');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className=''>
            <HeroSection />
            <div id={`${AnchorTags[0].url}`}><ServicesSection /></div>
            <div id={`${AnchorTags[1].url}`}><PortfolioSection /></div>
            <TestimonialSection />
            <Alliances />
            <div id={`${AnchorTags[2].url}`}><UsSection /></div>
            <div><BlogSection /></div>
            {/* <div id={`${AnchorTags[4].url}`}><ContactSection /></div> */}

            <div><BackToTop /></div>
        </div>
    )
}

export default HomePage