import React from 'react'
import Colors from '../../constants/Colors'

const ServiceCard = ({ service }) => {
    const { icon, title, description, content } = service;
    return (
        <div className='service-card'>
            <div className='px-4 pt-5 pb-4'>
                <p><i style={{ fontSize: '30px', color: Colors.green }} className={icon}></i></p>
                <p className='fw-bold text-capitalize fs-14'>{title}</p>
                <p className='fw-regular fs-16' style={{ color: '#333333' }}>{description}</p>
                <ul>
                    {
                        content.map((item, index) => {
                            return (
                                <li key={index} className='fw-regular fs-16' style={{ color: '#333333' }}>{item}</li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}

export default ServiceCard