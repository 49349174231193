import React from 'react'

const BackToTop = () => {
    return (
        <div className='d-flex justify-content-center mb-5'>
            <button
                onClick={() => { window.scrollTo(0, 0) }}
                className='btn rounded-circle text-white'
                style={{ backgroundColor: 'rgba(233, 70, 70, 1)', width: '74px', height: '74px' }}
            >
                <i className="fa-solid fa-chevron-up fs-32"></i>
            </button>
        </div>
    )
}

export default BackToTop