import Carousel from 'react-bootstrap/Carousel'
import Colors from '../constants/Colors'
import UsCard from '../components/general/UsCard'
import SectionTitle from '../components/general/SectionTitle'
import { useTranslation } from 'react-i18next';

const UsSection = () => {
    const { t } = useTranslation();

    const Team = t("team.list")
    return (
        <div style={{ backgroundColor: Colors.orange }} className="pt-5">
            <div className="container py-5">
                <SectionTitle text={t("team.title")} color={Colors.white} />
                <Carousel
                    indicators={false}
                    className='mt-5'
                    nextIcon={<span className="fa-solid fa-caret-right fs-50" />}
                    prevIcon={<span className="fa-solid fa-caret-left fs-50" />}
                >
                    {Team.map((colaborator, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <UsCard colaborator={colaborator} />
                            </Carousel.Item>)
                    })}
                </Carousel>
            </div>
        </div>
    )
}

export default UsSection