import React from 'react'
import Images from '../../constants/Images'

const Brand = () => {
    return (
        <div>
            <img className='mx-auto' src={Images.logo} alt="" />
        </div>
    )
}

export default Brand