import React from 'react'
import SectionTitle from '../components/general/SectionTitle'
import Colors from '../constants/Colors'
import { useTranslation } from 'react-i18next';

const Alliances = () => {
    const { t } = useTranslation();

    return (
        <div style={{ backgroundColor: Colors.white }} className="py-5">
            <div className="container py-5">
                <SectionTitle text={t("alliances")} color={Colors.orange} />

                <div className="row row-cols-1 row-cols-lg-4 d-flex justify-content-center align-items-center g-4">
                    <div className="col">
                        <img className='img-fluid p-4' src={process.env.PUBLIC_URL + '/images/alliances/dia.png'} alt="" />
                    </div>
                    <div className="col">
                        <img className='img-fluid p-4' src={process.env.PUBLIC_URL + '/images/alliances/oea.png'} alt="" />
                    </div>
                    <div className='col'>
                        <img className='img-fluid p-4' src={process.env.PUBLIC_URL + '/images/alliances/govtech.png'} alt="" />
                    </div>
                    <div className="col">
                        <div className='d-flex justify-content-center'>
                            <img className='img-fluid p-4 w-75' src={process.env.PUBLIC_URL + '/images/alliances/trust.png'} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Alliances