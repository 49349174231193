import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import PortfolioCard from '../components/general/PortfolioCard'
import Colors from '../constants/Colors'
import SectionTitle from '../components/general/SectionTitle'
import { useTranslation } from 'react-i18next';

const PortfolioSection = () => {
    const { t } = useTranslation();

    const Portfolio = t("portfolio.list");
    return (
        <div className='bg-white py-5 mt-5 d-flex flex-column align-items-center'>
            <SectionTitle color={Colors.green} text={t("portfolio.title")} />
            <div className='px-3'>
                <p className='fw-medium text-center fs-22 m-0' style={{ color: Colors.lightGray }}>{t("portfolio.subtitle1")}</p>
                <p className='fw-medium text-center fs-22' style={{ color: Colors.lightGray }}>{t("portfolio.subtitle2")}</p>
            </div>
            <div className='container px-0'>
                <Carousel
                    indicators={false}
                    nextIcon={<span className="fa-solid fa-caret-right fs-50 text-black" />}
                    prevIcon={<span className="fa-solid fa-caret-left fs-50 text-black" />}
                >
                    {
                        Portfolio.map((item, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <PortfolioCard data={item} />
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>
        </div>
    )
}

export default PortfolioSection