import React from 'react'
import Colors from '../constants/Colors'
import SectionTitle from '../components/general/SectionTitle'
import ServiceCard from '../components/general/ServiceCard'
import { useTranslation } from 'react-i18next';

const ServicesSection = () => {
    const { t } = useTranslation();
    const servicesData = t("services.list");
    return (
        <div className='py-5' style={{ backgroundColor: Colors.green }}>
            <div className='container px-0 pt-0 py-md-5'>
                <SectionTitle text={t("services.title")} color={Colors.white} />
                <div className='d-flex align-items-center flex-column px-3'>
                    <span className='fw-medium text-center fs-22' style={{ color: Colors.white }}>{t("services.subtitle1")}</span>
                    <p className='fw-medium text-center fs-22' style={{ color: Colors.white }}>{t("services.subtitle2")}</p>
                </div>
                <div className='container pt-3 pt-md-5'>
                    <div className='row row-cols-1 row-cols-lg-2 row-cols-xl-3 d-flex justify-content-center g-4'>
                        {servicesData.map((service, index) => {
                            return (
                                <div key={index} className='col'>
                                    <ServiceCard service={service} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesSection