import React from 'react'
import Colors from '../constants/Colors'
import SectionTitle from '../components/general/SectionTitle'
import Images from '../constants/Images'
import usePosts from '../hooks/usePosts'
import { useTranslation } from 'react-i18next';

const BlogSection = () => {
    const { t } = useTranslation();

    const [posts] = usePosts();
    return (
        <div className='py-5 mt-5'>
            <div className='d-flex justify-content-center align-items-center mb-5 mx-3'>
                <div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <img className='mb-3 me-4' src={Images.icon} alt="" />
                        <SectionTitle text='Click' color={Colors.red} />
                    </div>
                    <p className='text-center text-uppercase' style={{ color: Colors.red, fontSize: '30px' }}>
                        {t("blog.subtitle")}
                    </p>
                </div>

            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-lg-2 d-flex justify-content-center mx-xl-5 g-4'>
                    {
                        posts.map((post, index) => {
                            return (
                                index <= 3 &&
                                <div key={index} className='col'>
                                    <div className='blog-card'>
                                        <div className='p-5 d-flex flex-column justify-content-between h-100'>
                                            <p className='fw-medium fs-22 blog-title' style={{ color: Colors.red }}>{post.title.rendered}</p>
                                            <p className='fw-regular fs-16 excerpt-container mb-0' dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} ></p>
                                            <div>
                                                <a href={post.link} target="_blank" rel='noreferrer' type='button' className='btn btn-light rounded-pill mt-3 fs-16 fw-medium'>{t("blog.button")}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogSection